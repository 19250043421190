<template>
  <el-container class="container">
    <el-aside class="aside">
      <el-menu
        background-color="#FFFFFF"
        text-color="#222222"
        active-text-color="#FFFFFF"
        :default-active="activeIndex"
        @select="handleSelect"
      >
        <el-menu-item-group title="业务说明">
          <el-menu-item index="1"
            ><div class="point" />
            <div class="text">专利服务</div>
          </el-menu-item>
          <el-menu-item index="2"
            ><div class="point" />
            <div class="text">商标</div>
          </el-menu-item>
          <el-menu-item index="3" class="last"
            ><div class="point" />
            <div class="text">其他</div>
          </el-menu-item>
        </el-menu-item-group>
      </el-menu>
    </el-aside>
    <router-view />
  </el-container>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      activeIndex(state) {
        return state.business.activeIndex;
      },
    }),
  },
  methods: {
    handleSelect(index) {
      switch (index) {
        case "1":
          this.$router.push({ name: "businessPatent" });
          break;
        case "2":
          this.$router.push({ name: "businessTrademark" });
          break;
        case "3":
          this.$router.push({ name: "businessOthers" });
          break;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    store.commit("navbar/SET_POSITION_BOTTOM");
    let pageIndex = { activeIndex: "6" };
    store.commit("navbar/SET_ACTIVEINDEX", pageIndex);
    if (to.name === "business") {
      next((vm) => {
        vm.$router.push({ name: "businessPatent" });
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="stylus" scoped>
.container {
  padding: 36px 120px 0px 120px;
}

.aside {
  width: 240px !important;
  padding-bottom: 176px;
}

.el-menu {
  border: none;
  width: 240px;

  /deep/ .el-menu-item-group__title {
    padding-left: 40px !important;
    padding-top: 21px;
    padding-bottom: 21px;
    font-size: 22px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #2C53B0;
    line-height: 30px;
    background-color: #F3F5FB;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .el-menu-item {
    height: fit-content;
    padding-left: 40px !important;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 15px;
    font-family: PingFang-SC;
    font-weight: 400;
    color: #222222;
    line-height: 22px;
    margin-top: 1px;
    background-color: #F3F5FB !important;
  }

  .is-active {
    background-color: #2C53B0 !important;
    font-weight: 800;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    
    .point {
      background-color: #FFFFFF;
    }
  }

  .last {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .point {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 1px;
    background-color: #222222;
  }

  .text {
    margin-left: 9px;
    display: inline-block;
  }
}
@media only screen and (max-width: 600px) {
   .container {
      padding: 0px
      display block
   }
   >>>.aside {
      padding-bottom 36px
      margin-top 36px
      margin-left auto 
      margin-right auto
   }
   >>>.main{
      padding-left: 24px !important
      padding-right:24px !important
      margin 36px auto
   }
}
</style>
